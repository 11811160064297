import React from 'react';
import {
    Container,
    Box,
    Typography,
    Paper,
    ThemeProvider,
    DefaultPhxLightTheme,
} from '@gosolo/components';
import soloLogoWhite from '../../assets/images/solo-logo-black.png';

interface AuthLayoutProps {
    title: string;
    subtitle: string;
}

const AuthLayout = ({children, title, subtitle}) => {
    return (
        <ThemeProvider theme={DefaultPhxLightTheme}>
            <Container
                component="main"
                maxWidth={false}
                disableGutters
                sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: 'auto',
                        maxWidth: {xs: '90%', sm: 360},
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <img src={soloLogoWhite} alt="Solo Logo" style={{maxWidth: '30%', marginBottom: '10px'}}/>
                        <Typography component="h1" variant="h6" sx={{margin: '10px 0'}}>
                            {title}
                        </Typography>
                        <Typography variant="body2" sx={{textAlign: 'center'}}>
                            {subtitle}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        {children}
                    </Box>
                </Paper>
            </Container>
        </ThemeProvider>
    );
};

export default AuthLayout;
